import React from "react"

import { HeaderRender, Section } from "../components/elements"
import Layout from "../components/layouts/Layout"
import { AlignCenter, SEO } from "../components/utilities"

import banner from "../images/placeholder/section-one-temp.jpg"

const ThankYouPage = () => (
  <Layout
    image={banner}
    bodyRender={() => <HeaderRender>We Will Be In Touch Soon!</HeaderRender>}
  >
    <SEO
      title="Thank You For Contacting Us | Siferds"
      description="We have received your message and will be in touch with as soon as we
          can. Thank you!"
    />
    <Section modifiers={"danger"}>
      <AlignCenter>
        <h1>Thank you!</h1>
        <p>
          We have received your message and will be in touch with as soon as we
          can. Thank you!
        </p>
      </AlignCenter>
    </Section>
  </Layout>
)

export default ThankYouPage
